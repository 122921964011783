import { createApp, h } from 'vue'
import Toast from './Toast.vue'

export function removeElement(el) {
  if (typeof el.remove !== 'undefined') el.remove()
  else el.parentNode.removeChild(el)
}

export function spawn(propsData) {
  const container = document.getElementById('toast-container')
  const mountNode = document.createElement('div')
  container.appendChild(mountNode)

  const app = createApp({
    render() {
      return h(Toast, propsData)
    },
  })

  app.mount(mountNode)

  return app
}

export function success(message, title, timeout = 5, progress = true) {
  spawn({
    type: 'success',
    title,
    message,
    timeout,
    progress,
    classToast: 'bg-green-600',
    classTitle: 'text-green-100',
    classMessage: 'text-green-200',
    classClose: 'text-green-300',
    classTimeout: 'bg-green-800',
  })
}

export function error(message, title, timeout = 5, progress = true) {
  spawn({
    type: 'danger',
    title,
    message,
    timeout,
    progress,
    classToast: 'bg-red-600',
    classTitle: 'text-red-100',
    classMessage: 'text-red-200',
    classClose: 'text-red-300',
    classTimeout: 'bg-red-800',
  })
}

export function info(message, title, timeout = 5, progress = true) {
  spawn({
    type: 'info',
    title,
    message,
    timeout,
    progress,
    classToast: 'bg-blue-600',
    classTitle: 'text-blue-100',
    classMessage: 'text-blue-200',
    classClose: 'text-blue-300',
    classTimeout: 'bg-blue-800',
  })
}

export function warning(message, title, timeout = 5, progress = true) {
  spawn({
    type: 'warning',
    title,
    message,
    timeout,
    progress,
    classToast: 'bg-yellow-600',
    classTitle: 'text-yellow-100',
    classMessage: 'text-yellow-200',
    classClose: 'text-yellow-300',
    classTimeout: 'bg-yellow-800',
  })
}

export function show(type, message, title, timeout = 5, progress = true) {
  spawn({
    title,
    type,
    message,
    timeout,
    progress,
  })
}

export default {
  success,
  error,
  info,
  warning,
  show,
}
