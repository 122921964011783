<template>
  <transition
    enter-active-class="transition duration-300 ease-out transform"
    enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
    enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-active-class="duration-100 ease-in transform"
    leave-class="translate-y-0 opacity-100 sm:translate-x-0"
    leave-to-class="translate-y-1 opacity-0 sm:translate-x-1 sm:translate-y-0"
  >
    <div
      v-if="active && primary === false"
      :class="classToastAll"
      class="relative w-full max-w-sm mb-4 overflow-hidden rounded-lg shadow-lg pointer-events-auto"
    >
      <div
        v-if="progress && timeout"
        :class="classTimeout"
        class="absolute bottom-0 left-0 right-0 h-1 rounded"
        :style="progressStyle"
      ></div>
      <div :class="classToastAll" class="overflow-hidden rounded-lg shadow-xs z-100">
        <div class="p-4">
          <div class="flex items-start">
            <div class="flex-shrink-0">
              <div v-if="type === 'success'" class="p-1 border-2 border-green-200 rounded-full">
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    class="text-green-300 fill-current"
                    d="M504.5 144.42L264.75 385.5 192 312.59l240.11-241a25.49 25.49 0 0 1 36.06-.14l.14.14L504.5 108a25.86 25.86 0 0 1 0 36.42z"
                  />
                  <path
                    class="text-green-400 fill-current"
                    d="M264.67 385.59l-54.57 54.87a25.5 25.5 0 0 1-36.06.14l-.14-.14L7.5 273.1a25.84 25.84 0 0 1 0-36.41l36.2-36.41a25.49 25.49 0 0 1 36-.17l.16.17z"
                  />
                </svg>
              </div>
              <div v-if="type === 'info'" class="p-1 border-2 border-blue-200 rounded-full">
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                  <path
                    class="text-blue-300 fill-current"
                    d="M20 448h152a20 20 0 0 1 20 20v24a20 20 0 0 1-20 20H20a20 20 0 0 1-20-20v-24a20 20 0 0 1 20-20z"
                  />
                  <path
                    class="text-blue-400 fill-current"
                    d="M96 128a64 64 0 1 0-64-64 64 64 0 0 0 64 64zm28 64H20a20 20 0 0 0-20 20v24a20 20 0 0 0 20 20h28v192h96V212a20 20 0 0 0-20-20z"
                  />
                </svg>
              </div>
              <div v-if="type === 'warning'" class="p-1 border-2 border-yellow-200 rounded-full">
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                  <path class="text-yellow-300 fill-current" d="M96 512a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80z" />
                  <path
                    class="text-yellow-400 fill-current"
                    d="M49.22 0h93.56a24 24 0 0 1 24 25.2l-13.63 272a24 24 0 0 1-24 22.8H62.84a24 24 0 0 1-24-22.8l-13.59-272A24 24 0 0 1 49.22 0z"
                  />
                </svg>
              </div>
              <div v-if="type === 'danger'" class="p-1 border-2 border-red-200 rounded-full">
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                  <path class="text-red-300 fill-current" d="M96 512a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80z" />
                  <path
                    class="text-red-400 fill-current"
                    d="M49.22 0h93.56a24 24 0 0 1 24 25.2l-13.63 272a24 24 0 0 1-24 22.8H62.84a24 24 0 0 1-24-22.8l-13.59-272A24 24 0 0 1 49.22 0z"
                  />
                </svg>
              </div>
              <div v-if="icon !== false">
                <component :is="icon" :primary="iconPrimary" :secondary="iconSecondary" class="w-6 h-6"></component>
              </div>
            </div>
            <div class="ml-3 flex-1 pt-0.5">
              <p v-if="title" :class="classTitle" class="capitalize body-14-aa font-plain">
                {{ title }}
              </p>
              <p
                :class="[classMessage, { 'mt-1': title }]"
                class="capitalize body-14-aa font-plain"
                v-html="message"
              ></p>
            </div>
            <div class="flex flex-shrink-0 ml-4">
              <button
                class="inline-flex text-gray-400 transition duration-150 ease-in-out focus:outline-none focus:text-gray-500"
                @click="destroy"
              >
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                  <path
                    :class="[classClose, 'fill-current']"
                    d="M9.21,356.07a31.46,31.46,0,0,0,0,44.48l22.24,22.24a31.46,31.46,0,0,0,44.48,0L176,322.72,109.28,256ZM342.79,111.45,320.55,89.21a31.46,31.46,0,0,0-44.48,0L176,189.28,242.72,256,342.79,155.93a31.46,31.46,0,0,0,0-44.48Z"
                  />
                  <path
                    :class="[classClose, 'fill-current']"
                    d="M342.79,356.07a31.46,31.46,0,0,1,0,44.48l-22.24,22.24a31.46,31.46,0,0,1-44.48,0L9.21,155.93a31.46,31.46,0,0,1,0-44.48L31.45,89.21a31.46,31.46,0,0,1,44.48,0Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="active && primary !== false && secondary !== false"
      :class="classToastAll"
      class="w-full max-w-md mb-4 rounded-lg shadow-lg pointer-events-auto"
    >
      <div
        v-if="progress && timeout"
        class="absolute bottom-0 left-0 right-0 h-1 bg-gray-100 rounded dark:bg-gray-900"
        :style="progressStyle"
      ></div>
      <div class="flex rounded-lg shadow-xs">
        <div class="flex items-center flex-1 p-4">
          <div class="flex-shrink-0 mr-4">
            <div v-if="type === 'success'" class="p-1 border-2 border-green-200 rounded-full">
              <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  class="text-green-300 fill-current"
                  d="M504.5 144.42L264.75 385.5 192 312.59l240.11-241a25.49 25.49 0 0 1 36.06-.14l.14.14L504.5 108a25.86 25.86 0 0 1 0 36.42z"
                />
                <path
                  class="text-green-400 fill-current"
                  d="M264.67 385.59l-54.57 54.87a25.5 25.5 0 0 1-36.06.14l-.14-.14L7.5 273.1a25.84 25.84 0 0 1 0-36.41l36.2-36.41a25.49 25.49 0 0 1 36-.17l.16.17z"
                />
              </svg>
            </div>
            <div v-if="type === 'info'" class="p-1 border-2 border-blue-200 rounded-full">
              <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                <path
                  class="text-blue-300 fill-current"
                  d="M20 448h152a20 20 0 0 1 20 20v24a20 20 0 0 1-20 20H20a20 20 0 0 1-20-20v-24a20 20 0 0 1 20-20z"
                />
                <path
                  class="text-blue-400 fill-current"
                  d="M96 128a64 64 0 1 0-64-64 64 64 0 0 0 64 64zm28 64H20a20 20 0 0 0-20 20v24a20 20 0 0 0 20 20h28v192h96V212a20 20 0 0 0-20-20z"
                />
              </svg>
            </div>
            <div v-if="type === 'warning'" class="p-1 border-2 border-yellow-200 rounded-full">
              <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                <path class="text-yellow-300 fill-current" d="M96 512a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80z" />
                <path
                  class="text-yellow-400 fill-current"
                  d="M49.22 0h93.56a24 24 0 0 1 24 25.2l-13.63 272a24 24 0 0 1-24 22.8H62.84a24 24 0 0 1-24-22.8l-13.59-272A24 24 0 0 1 49.22 0z"
                />
              </svg>
            </div>
            <div v-if="type === 'danger'" class="p-1 border-2 border-red-200 rounded-full">
              <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                <path class="text-red-300 fill-current" d="M96 512a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80z" />
                <path
                  class="text-red-400 fill-current"
                  d="M49.22 0h93.56a24 24 0 0 1 24 25.2l-13.63 272a24 24 0 0 1-24 22.8H62.84a24 24 0 0 1-24-22.8l-13.59-272A24 24 0 0 1 49.22 0z"
                />
              </svg>
            </div>
            <div v-if="icon !== false">
              <component :is="icon" :primary="iconPrimary" :secondary="iconSecondary" class="w-6 h-6"></component>
            </div>
          </div>
          <div class="w-full">
            <p v-if="title" :class="classTitle" class="text-sm font-medium leading-5">
              {{ title }}
            </p>
            <p :class="[classMessage, { 'mt-1': title }]" class="text-sm leading-5" v-html="message"></p>
          </div>
        </div>
        <div class="flex border-l border-gray-200">
          <div class="flex flex-col -ml-px">
            <div class="flex flex-1 h-0 border-b border-gray-200">
              <button
                class="flex items-center justify-center w-full px-4 py-3 -mb-px text-sm font-medium leading-5 transition duration-150 ease-in-out border border-transparent rounded-tr-lg focus:outline-none"
                :class="classPrimary"
                @click="primaryAction"
              >
                {{ primary.label }}
              </button>
            </div>
            <div class="flex flex-1 h-0 -mt-px">
              <button
                class="flex items-center justify-center w-full px-4 py-3 text-sm font-medium leading-5 transition duration-150 ease-in-out border border-transparent rounded-br-lg focus:outline-none"
                :class="classSecondary"
                @click="secondaryAction"
              >
                {{ secondary.label }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="active && primary !== false && secondary === false"
      :class="classToastAll"
      class="w-full max-w-md mb-4 rounded-lg shadow-lg pointer-events-auto"
    >
      <div
        v-if="progress && timeout"
        class="absolute bottom-0 left-0 right-0 h-1 bg-gray-100 rounded dark:bg-gray-900"
        :style="progressStyle"
      ></div>
      <div class="overflow-hidden rounded-lg shadow-xs">
        <div class="p-4">
          <div class="flex items-center">
            <div class="flex-shrink-0 mr-4">
              <div v-if="type === 'success'" class="p-1 border-2 border-green-200 rounded-full">
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    class="text-green-300 fill-current"
                    d="M504.5 144.42L264.75 385.5 192 312.59l240.11-241a25.49 25.49 0 0 1 36.06-.14l.14.14L504.5 108a25.86 25.86 0 0 1 0 36.42z"
                  />
                  <path
                    class="text-green-400 fill-current"
                    d="M264.67 385.59l-54.57 54.87a25.5 25.5 0 0 1-36.06.14l-.14-.14L7.5 273.1a25.84 25.84 0 0 1 0-36.41l36.2-36.41a25.49 25.49 0 0 1 36-.17l.16.17z"
                  />
                </svg>
              </div>
              <div v-if="type === 'info'" class="p-1 border-2 border-blue-200 rounded-full">
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                  <path
                    class="text-blue-300 fill-current"
                    d="M20 448h152a20 20 0 0 1 20 20v24a20 20 0 0 1-20 20H20a20 20 0 0 1-20-20v-24a20 20 0 0 1 20-20z"
                  />
                  <path
                    class="text-blue-400 fill-current"
                    d="M96 128a64 64 0 1 0-64-64 64 64 0 0 0 64 64zm28 64H20a20 20 0 0 0-20 20v24a20 20 0 0 0 20 20h28v192h96V212a20 20 0 0 0-20-20z"
                  />
                </svg>
              </div>
              <div v-if="type === 'warning'" class="p-1 border-2 border-yellow-200 rounded-full">
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                  <path class="text-yellow-300 fill-current" d="M96 512a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80z" />
                  <path
                    class="text-yellow-400 fill-current"
                    d="M49.22 0h93.56a24 24 0 0 1 24 25.2l-13.63 272a24 24 0 0 1-24 22.8H62.84a24 24 0 0 1-24-22.8l-13.59-272A24 24 0 0 1 49.22 0z"
                  />
                </svg>
              </div>
              <div v-if="type === 'danger'" class="p-1 border-2 border-red-200 rounded-full">
                <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512">
                  <path class="text-red-300 fill-current" d="M96 512a80 80 0 1 1 80-80 80.09 80.09 0 0 1-80 80z" />
                  <path
                    class="text-red-400 fill-current"
                    d="M49.22 0h93.56a24 24 0 0 1 24 25.2l-13.63 272a24 24 0 0 1-24 22.8H62.84a24 24 0 0 1-24-22.8l-13.59-272A24 24 0 0 1 49.22 0z"
                  />
                </svg>
              </div>

              <div v-if="icon !== false">
                <component :is="icon" :primary="iconPrimary" :secondary="iconSecondary" class="w-6 h-6"></component>
              </div>
            </div>
            <div class="flex justify-between flex-1">
              <p class="flex-1 text-sm leading-5" v-html="message"></p>
              <button
                class="flex-shrink-0 ml-3 text-sm font-medium leading-5 transition duration-150 ease-in-out focus:outline-none focus:underline'"
                :class="classSingle"
                @click="primaryAction"
              >
                {{ primary.label }}
              </button>
            </div>
            <div class="flex flex-shrink-0 ml-4">
              <button
                class="inline-flex text-gray-400 transition duration-150 ease-in-out focus:outline-none focus:text-gray-500"
                @click="destroy"
              >
                <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                  <path
                    :class="[classClose, 'fill-current']"
                    d="M9.21,356.07a31.46,31.46,0,0,0,0,44.48l22.24,22.24a31.46,31.46,0,0,0,44.48,0L176,322.72,109.28,256ZM342.79,111.45,320.55,89.21a31.46,31.46,0,0,0-44.48,0L176,189.28,242.72,256,342.79,155.93a31.46,31.46,0,0,0,0-44.48Z"
                  />
                  <path
                    :class="[classClose, 'fill-current']"
                    d="M342.79,356.07a31.46,31.46,0,0,1,0,44.48l-22.24,22.24a31.46,31.46,0,0,1-44.48,0L9.21,155.93a31.46,31.46,0,0,1,0-44.48L31.45,89.21a31.46,31.46,0,0,1,44.48,0Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { removeElement } from './toast.js'
export default {
  props: {
    title: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default: 'Please specify a <b>message</b>',
    },
    type: {
      type: String,
      required: false,
      validate: (type) => {
        return ['success', 'info', 'danger', 'warning'].includes(type)
      },
      default: '',
    },
    progress: {
      type: Boolean,
      required: false,
      default: true,
    },
    icon: {
      type: [Boolean, String],
      required: false,
      default: false,
    },
    iconPrimary: {
      type: String,
      required: false,
      default: '',
    },
    iconSecondary: {
      type: String,
      required: false,
      default: '',
    },
    timeout: {
      type: [Boolean, Number],
      required: false,
      default: 2,
    },
    primary: {
      type: [Boolean, Object],
      required: false,
      default: false,
    },
    secondary: {
      type: [Boolean, Object],
      required: false,
      default: false,
    },
    classToast: {
      type: String,
      required: false,
      default: 'bg-white dark:bg-gray-800',
    },
    classTitle: {
      type: String,
      required: false,
      default: 'text-gray-900 dark:text-gray-100',
    },
    classMessage: {
      type: String,
      required: false,
      default: 'text-gray-500 dark:text-gray-400',
    },
    classPrimary: {
      type: String,
      required: false,
      default:
        'text-indigo-600 hover:text-indigo-500 focus:shadow-outline-blue focus:border-blue-300 active:text-indigo-700 active:bg-gray-50',
    },
    classSecondary: {
      type: String,
      required: false,
      default:
        'text-gray-700 hover:text-gray-500 focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50',
    },
    classSingle: {
      type: String,
      required: false,
      default: 'text-indigo-600 hover:text-indigo-500',
    },
    classClose: {
      type: String,
      required: false,
      default: 'text-gray-400 dark:text-gray-600',
    },
    classTimeout: {
      type: String,
      required: false,
      default: 'bg-gray-200 dark:bg-gray-700',
    },
    defaults: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      active: false,
      interval: false,
      timeLeft: false,
      speed: 100,
    }
  },

  computed: {
    classToastAll() {
      if (this.defaults && this.defaults.classToast) {
        return [this.classToast, this.defaults.classToast]
      }
      if (this.classToast) return [this.classToast]
      return []
    },
    timeLeftPercent() {
      return Math.round((((this.timeLeft * 100) / (this.timeout * 1000)) * 100) / 100)
    },
    progressStyle() {
      return `width: ${this.timeLeftPercent}%; transition: width 0.1s linear;`
    },
  },

  mounted() {
    this.active = true
    if (this.timeout > 0) {
      this.timeLeft = this.timeout * 1000
      this.interval = setInterval(() => this.updateTime(), this.speed)
    }
  },

  methods: {
    updateTime() {
      this.timeLeft -= this.speed
      if (this.timeLeft === 0) this.destroy()
    },
    destroy() {
      this.active = false
      clearInterval(this.interval)
      setTimeout(() => {
        this.$.appContext.app.unmount()
        removeElement(this.$el)
      }, 1000)
    },
    primaryAction() {
      this.primary.action()
      this.destroy()
    },
    secondaryAction() {
      this.secondary.action()
      this.destroy()
    },
  },
}
</script>
